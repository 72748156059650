/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import "variables";
@import "mixins";

body.login {
	background: var(--color11);
	font-family: var(--font-family-primary);
	h1 {
		a {
			width: rem(250);
			height: rem(150);
			background: url(../img/logo.svg) no-repeat center center / contain;
		}
	}
	form {
		border: none;
		border-radius: rem(20);
	}
}

#login {
	background: none;
	box-shadow: none;
}

.login #backtoblog,
.login #nav,
.login .privacy-policy-page-link {
	a {
		color: var(--text-color);
		&:hover {
			color: var(--color2);
			text-decoration: underline;
		}
	}
}

.wp-core-ui,
.language-switcher {
	input.button,
	.button-large,
	.button-primary {
		background: var(--color1);
		color: #FFF;
		border: none;
		border-radius: rem(100);
		box-shadow: none;
		text-shadow: none;
		text-transform: none;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: var(--color2) !important;
			color: #FFF;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
