/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #FFAD00;
	--color2:               #DB001C;
	--color3:               #865E9D;
	--color4:               #D6006D;
	--color5:               #006DB7;
	--color6:               #0094AA;
	--color7:               #00B7A6;
	--color8:               #A0D035;
	--color9:               #F15C3A;
	--color10:              #FFF7E5;
	--color11:              #F4ECE8;
	--color12:              #F2F8FB;

	--theme-color:          var(--color1);

	// TYPOGRAPHY
	// ---------------

	--text-color:            #3B3836;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Roboto", Helvetica, Arial, sans-serif;
	--font-family-secondary: "Roboto Slab", var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--text-color);
	--hr-color:              #DDD;

	// SIZING
	// ---------------

	--border-radius:         1.25rem;

}

@media (min-width: 1200px)  {

	:root {

		--border-radius: 1.875rem;

	}

}